import Vue from 'vue'
import VueSocketIO from 'vue-socket.io'
import App from './App.vue'
import router from './router'
import store from './store'
import Loading from './components/Loading.vue'
import './plugins/ClickOutside'
import './plugins/Dayjs'
import './plugins/Filters'
import './plugins/Icons'
import './assets/css/tailwind.css'

Vue.config.productionTip = false

Vue.component('loading', Loading)

Vue.use(new VueSocketIO({
  debug: true,
  connection: `${process.env.VUE_APP_CHAT_API}`
}))

new Vue({
  router,
  store,
  sockets: {
    connect: () => {}
  },
  render: (h) => h(App)
}).$mount('#app')
