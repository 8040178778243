<template>
  <div class="w-screen">
    <router-view />
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
</style>

<style>
* {
  font-family: 'Sarabun', sans-serif;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(185, 185, 195, 1);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.elevation-3 {
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .14), 0 1px 8px 0 rgba(0, 0, 0, .12);
}

.elevation-t-3 {
  box-shadow: 0 -3px 3px -2px rgba(0, 0, 0, .2), 0 -3px 4px 0 rgba(0, 0, 0, .14), 0 -1px 8px 0 rgba(0, 0, 0, .12);
}

.elevation-5 {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 5px 8px 0 rgba(0, 0, 0, .14), 0 1px 14px 0 rgba(0, 0, 0, .12);
}
</style>
