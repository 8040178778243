const state = {
  stickers: [
    {
      packageId: '789',
      stickerIds: [
        '10855',
        '10856',
        '10857',
        '10858',
        '10859',
        '10860',
        '10861',
        '10862',
        '10863',
        '10864',
        '10865',
        '10866',
        '10867',
        '10868',
        '10869',
        '10870',
        '10871',
        '10872',
        '10873',
        '10874',
        '10875',
        '10876',
        '10877',
        '10878',
        '10879',
        '10880',
        '10881',
        '10882',
        '10883',
        '10884',
        '10885',
        '10886',
        '10887',
        '10888',
        '10889',
        '10890',
        '10891',
        '10892',
        '10893',
        '10894'
      ]
    },
    {
      packageId: '6325',
      stickerIds: [
        '10979904',
        '10979905',
        '10979906',
        '10979907',
        '10979908',
        '10979909',
        '10979910',
        '10979911',
        '10979912',
        '10979913',
        '10979914',
        '10979915',
        '10979916',
        '10979917',
        '10979918',
        '10979919',
        '10979920',
        '10979921',
        '10979922',
        '10979923',
        '10979924',
        '10979925',
        '10979926',
        '10979927'
      ]
    },
    {
      packageId: '8515',
      stickerIds: [
        '16581242',
        '16581243',
        '16581244',
        '16581245',
        '16581246',
        '16581247',
        '16581248',
        '16581249',
        '16581250',
        '16581251',
        '16581252',
        '16581253',
        '16581254',
        '16581255',
        '16581256',
        '16581257',
        '16581258',
        '16581259',
        '16581260',
        '16581261',
        '16581262',
        '16581263',
        '16581264',
        '16581265'
      ]
    },
    {
      packageId: '1070',
      stickerIds: [
        '17839',
        '17840',
        '17841',
        '17842',
        '17843',
        '17844',
        '17845',
        '17846',
        '17847',
        '17848',
        '17849',
        '17850',
        '17851',
        '17852',
        '17853',
        '17854',
        '17855',
        '17856',
        '17857',
        '17858',
        '17859',
        '17860',
        '17861',
        '17862',
        '17863',
        '17864',
        '17865'
      ]
    },
    {
      packageId: '11539',
      stickerIds: [
        '52114110',
        '52114111',
        '52114112',
        '52114113',
        '52114114',
        '52114115',
        '52114116',
        '52114117',
        '52114118',
        '52114119',
        '52114120',
        '52114121',
        '52114122',
        '52114123',
        '52114124',
        '52114125',
        '52114126',
        '52114127',
        '52114128',
        '52114129',
        '52114130',
        '52114131',
        '52114132',
        '52114133',
        '52114134',
        '52114135',
        '52114136',
        '52114137',
        '52114138',
        '52114139',
        '52114140',
        '52114141',
        '52114142',
        '52114143',
        '52114144',
        '52114145',
        '52114146',
        '52114147',
        '52114148',
        '52114149'
      ]
    },
    {
      packageId: '11537',
      stickerIds: [
        '52002734',
        '52002735',
        '52002736',
        '52002737',
        '52002738',
        '52002739',
        '52002740',
        '52002741',
        '52002742',
        '52002743',
        '52002744',
        '52002745',
        '52002746',
        '52002747',
        '52002748',
        '52002749',
        '52002750',
        '52002751',
        '52002752',
        '52002753',
        '52002754',
        '52002755',
        '52002756',
        '52002757',
        '52002758',
        '52002759',
        '52002760',
        '52002761',
        '52002762',
        '52002763',
        '52002764',
        '52002765',
        '52002766',
        '52002767',
        '52002768',
        '52002769',
        '52002770',
        '52002771',
        '52002772',
        '52002773'
      ]
    },
    {
      packageId: '6136',
      stickerIds: [
        '10551376',
        '10551377',
        '10551378',
        '10551379',
        '10551380',
        '10551381',
        '10551382',
        '10551383',
        '10551384',
        '10551385',
        '10551386',
        '10551387',
        '10551388',
        '10551389',
        '10551390',
        '10551391',
        '10551392',
        '10551393',
        '10551394',
        '10551395',
        '10551396',
        '10551397',
        '10551398',
        '10551399'
      ]
    }
  ]
}

const actions = {
  setStickers ({ commit }, payload) {
    commit('SET_STICKERS', payload)
  }
}

const mutations = {
  SET_STICKERS (state, payload) {
    state.stickers = payload
  }
}

const getters = {
  stickers: (state) => state.stickers
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
