import auth from '../../middlewares/auth'

export default {
  path: '',
  component: () => import('@/views/chat/index.vue'),
  children: [
    {
      path: 't/:uid',
      name: 'Chat',
      component: () => import('@/views/chat/pages/Chat.vue'),
      meta: {
        middleware: auth
      }
    }
  ]
}
