<template>
  <div class="login">
    Loading
  </div>
</template>

<script>
import AuthProvider from '@/resources/auth.provider'
import { setAuthUser, clearAuth } from '@/utils/auth.utils'

const AuthService = new AuthProvider()

export default {
  created () {
    clearAuth()
    this.checkPage()
  },
  methods: {
    checkPage () {
      if (this.$route.name === 'Logout') {
        setTimeout(() => {
          const uri = `${window.location.origin}${this.$route.path}`
          window.location.replace(`${process.env.VUE_APP_LOGIN}/logout?callback_uri=${encodeURI(uri)}`)
        }, 1000)
      } else {
        this.checkLogin()
      }
    },
    async login (token) {
      try {
        const loginResult = await AuthService.login(token)
        const auth = setAuthUser(loginResult.accessToken)
        if (auth) {
          this.$router.push({ name: 'Chat' })
        } else {
          throw new Error()
        }
      } catch (error) {
        console.error('login', error)
      }
    },
    checkLogin () {
      const { query } = this.$route
      const tokenRef = `${query.token_reference}`
      if (query && query.token_reference) {
        this.login(tokenRef)
      } else {
        setTimeout(() => {
          const uri = `${window.location.origin}${this.$route.path}`
          window.location.replace(`${process.env.VUE_APP_LOGIN}?callback_uri=${encodeURI(uri)}`)
        }, 1000)
      }
    }
  }
}
</script>

<style scoped>
.login {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
