import Vue from 'vue'
import Vuex from 'vuex'

import GuestModule from './modules/Guest'
import StickersModule from './modules/Stickers'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Guest: GuestModule,
    Stickers: StickersModule
  }
})
