<template>
  <div
    class="animate-spin"
    :style="{ 'font-size': size }">
    <icon
      icon="ant-design:loading-outlined"
      color="#000" />
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '36px'
    }
  }
}
</script>

<style scoped>

</style>
