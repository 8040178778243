const state = {
  selectedGuest: null
}

const actions = {
  setSelectedGuest ({ commit }, payload) {
    commit('SET_SELECTED_GUEST', payload)
  }
}

const mutations = {
  SET_SELECTED_GUEST (state, payload) {
    state.selectedGuest = payload
  }
}

const getters = {
  selectedGuest: (state) => state.selectedGuest
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
