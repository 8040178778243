import Vue from 'vue'
import VueRouter from 'vue-router'
import { getAuth } from '@/utils/auth.utils'
import Login from '../views/Login.vue'
import Chat from './modules/Chat'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Login
  },
  Chat
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !getAuth()) {
    return next({
      name: 'Login'
    })
  }
  return next()
})

export default router
