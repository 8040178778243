import Vue from 'vue'

Vue.filter('showNumberFormat', (number = 0) => {
  const num = parseFloat(number)
  if (num === 0 || num) {
    return num.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return '-'
})

Vue.filter('showKBFormat', (number = 0) => {
  let num = parseFloat(number)
  if (num === 0 || num) {
    num /= 1024
    return num.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return '-'
})
